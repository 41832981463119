import type {InjectionKey, Ref} from '@vue/runtime-core'
import type {
    TripDto,
    TripFoodJoinInsert,
    TripFoodDto,
    UserDto,
    TripRecipeDto,
    FoodDto, TripFoodId, RecipeDto, TripEdibleDto, CreateRecipeDto, UpdateRecipeDto, UserSettingsDto
} from '~/data/drizzle/schema/schemaTypes'
import type {ComputedRef} from 'vue'
import type {H3Error} from 'h3'

export const tripKey = Symbol() as InjectionKey<{
    tripId: Ref<string>,
    trip: Ref<TripDto | null>,
    tripDayCalorieTarget: (i: number) => number
    tripFood: Ref<(TripFoodDto | TripRecipeDto)[]>
    uniqueTripFoods: Ref<FoodDto[]>
    tripFoodByDay: ComputedRef<Record<number, (TripFoodDto | TripRecipeDto)[]>>
    tripFoodSummaryByDay: ComputedRef<Record<number, (TripFoodDto | TripRecipeDto)[]>>,
    refreshTripData: (tripId: string) => void,
    removeFoodFromTrip: (tripId: string, tripFoodId: TripFoodId) => Promise<void>
    addFoodToTrip: (tripId: string, foodId: number, day: number, servings: number, order: number) => Promise<void>
    updateTripFood: (tripFoodId: number, tripId: string, updates: Partial<TripFoodJoinInsert>) => Promise<TripEdibleDto>
    updateTripFoodOrder: (tripId: string, foods: TripEdibleDto[]) => Promise<void>
    updateTrip: (trip: TripDto) => Promise<TripDto>
}>
export const userKey = Symbol() as InjectionKey<{
    user: Ref<UserDto | undefined>
    settings: ComputedRef<UserSettingsDto | undefined | null>
    updateUserSettings: (settings: UserSettingsDto) => Promise<UserSettingsDto>
    refreshUser: () => Promise<void>
    isLoggedIn: ComputedRef<boolean>
    canEdit: (created_by: number | undefined) => boolean
    trips: Ref<TripDto[]>
    futureTrips: ComputedRef<TripDto[]>
    login: (username: string, password: string, redirect?: string) => Promise<UserDto>
    createAccount: (username: string, password: string, redirect?: string) => Promise<UserDto>
    logout: () => void
    createTrip: (description: string, start_date: number, length: number, calorie_target: number) => Promise<TripDto>
    deleteTrip: (tripId: string) => Promise<void>
    copyFoodTo: (tid: string, day: number, tripFood: TripEdibleDto[], food: (FoodDto | RecipeDto)[]) => Promise<TripEdibleDto[]>
}>
export const recipeKey = Symbol() as InjectionKey<{
    recipe: Ref<RecipeDto | undefined>
    canEdit: ComputedRef<boolean>
    updateRecipe: (recipe: UpdateRecipeDto) => Promise<RecipeDto | H3Error>
}>

export const getUser = () => {
    const data = inject(userKey)
    if (data == undefined)
        throw new Error('Must call `getUser()` from inside a `<UserProvider />`')
    return data
}

export const getTrip = () => {
    const data = inject(tripKey)
    if (data == undefined)
        throw new Error('Must call `getTrip()` from inside a `<TripProvider />`')
    return data
}

export const getRecipe = () => {
    const data = inject(recipeKey)
    if (data == undefined)
        throw new Error('Must call `getRecipe()` from inside a `<RecipeProvider />`')
    return data
}
