import {addAuthHeaders} from '~/utils/addAuthHeaders'
import {type UseFetchOptions, useLazyAsyncData} from '#app'
// @ts-ignore
import type {
    TripDto, TripEdibleDto,
    TripFoodDto,
    TripFoodId,
    TripFoodJoinInsert,
    TripRecipeDto,
    UserDto
} from '~/data/drizzle/schema/schemaTypes'
import type {IFoodOrder, IUpdate} from '~/data/models/interfaces'


export const useTripApi = () => {
    // Trip
    const getTrips = async () => $fetch<TripDto[]>('/api/trips')
    const getTrip = async (tripId: string, opts?: UseFetchOptions) => useFetch<TripDto>(`/api/trips/${tripId}`, opts)
    const getTripFood = async (tripId: number | string, opts?: UseFetchOptions) => useFetch<{
        food: TripFoodDto[],
        recipes: TripRecipeDto[]
    }>(`/api/trips/${tripId}/foods`, opts)
    // @ts-ignore
    const setFoodTag = async (tripId: string, tripFoodId: number, tag: string) => $fetch(`/api/trips/${tripId}/foods/${tripFoodId}/tag`, {
        method: 'put',
        body: {tag}
    })
    const addFoodToTrip = async (tripId: number | string, foodId: number, day: number, servings: number, order: number) =>
        $fetch<TripFoodDto>(`/api/trips/${tripId}/foods`, {
            method: 'post',
            body: {foodId, day, servings, order}
        })
    const copyFoodsToTrip = async (tripId: number | string, day: number, tripFoodIds: TripFoodId[] = [], foodIds: number[] = []) =>
        $fetch<TripEdibleDto[]>(`/api/trips/${tripId}/copyFoods`, {
            method: 'post',
            body: {day, tripFoodIds, foodIds}
        })
    const addRecipeToTrip = async (tripId: number | string, recipeId: number, day: number, servings: number, order: number) =>
        $fetch(`/api/trips/${tripId}/recipes`, {
            method: 'post',
            body: {recipeId, day, servings, order}
        })
    const updateTripFood = async (tripFoodId: number, tripId: number | string, updates: Partial<TripFoodJoinInsert>) =>
        await $fetch<TripFoodDto | TripRecipeDto>(`/api/trips/${tripId}/foods/${tripFoodId}`, {
            method: 'put',
            body: {
                id: tripFoodId,
                ...updates
            }
        })
    const deleteFood = async (tripId: number | string, tripFoodId: number) => {
        await $fetch(`/api/trips/${tripId}/foods/${tripFoodId}`, {
            method: 'delete'
        })
    }
    const deleteTrip = async (tripId: string) => {
        return await $fetch<TripDto>(`/api/trips/${tripId}`, {
                method: 'delete'
            }
        )
    }
    const updateTrip = async (t: TripDto) => {
        return await $fetch<TripDto>(`/api/trips/${t.id}`, {
                method: 'put', body: t
            }
        )
    }
    const createTrip = async (description: string, start_date: number, length: number, calorie_target: number) => {
        return await $fetch<TripDto>('/api/trips', {
                method: 'post', body: {
                    description,
                    start_date,
                    length,
                    calorie_target
                }
            }
        )
    }
    const updateFoodOrder = async (tripId: string, foods: TripEdibleDto[]) => {
        if (foods.length < 1) return [] as TripEdibleDto[]
        const updatedOrders = foods.map<IUpdate<IFoodOrder>>((food, i) => {
            return {
                prev: {
                    id: food.id,
                    tripId,
                    day: food.day,
                    order: food.order
                },
                next: {
                    id: food.id,
                    tripId,
                    day: food.day,
                    order: i
                }
            }
        })
        return $fetch<TripEdibleDto[]>(`/api/trips/${tripId}/foods/reorder`, {
            method: 'put', body: updatedOrders
        })
    }

    const explore = async () => useFetch<TripDto[]>('/api/explore/trips')


    return {
        createTrip, getTrip, updateTrip, deleteTrip,
        all: getTrips,
        getTripFood,
        addFoodToTrip,
        copyFoodsToTrip,
        addRecipeToTrip,
        updateTripFood,
        deleteFood,
        setFoodTag,
        updateFoodOrder,
        explore
    }
}

