import {daisyText} from '~/components/forms/daisyText'
import {daisyRadio} from '~/components/forms/daisyRadio'
import type {DefaultConfigOptions} from '@formkit/vue'

const config: DefaultConfigOptions = {
    config: {
        classes: {
            input: 'input input-bordered invalid:input-error w-full',
            outer: 'form-control',
            label: 'label label-text',
            help: 'label label-text-alt pt-1',
            messages: 'text-error',
            message: 'label label-text-alt bg-error text-error-content mt-1',
            legend: 'text-xl'
        }
    },
    inputs: {
        daisyText, daisyRadio
    }
}

export default config
