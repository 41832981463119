import type {UserDto} from '~/data/drizzle/schema/schemaTypes'

export default defineNuxtRouteMiddleware((to, from) => {
    let r = window.location.origin + to.fullPath
    const u = window.location.origin + from.fullPath
    const user = useCookie<UserDto | undefined>('user')

    fetch('https://plausible.bj13.us/api/event', {
        'body': JSON.stringify({
            n: 'pageview',
            u,
            d: null,
            p: {user: user.value?.username ?? 'anon'},
            r,
        }),
        'method': 'POST'
    })
})
