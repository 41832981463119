import { default as acknowledgementsEufl4Vd6hVMeta } from "/usr/src/app/pages/acknowledgements.vue?macro=true";
import { default as logink8UcAHPYydMeta } from "/usr/src/app/pages/auth/login.vue?macro=true";
import { default as foods0A4GGoaxxpMeta } from "/usr/src/app/pages/explore/foods.vue?macro=true";
import { default as recipesUNYYhBSb1mMeta } from "/usr/src/app/pages/explore/recipes.vue?macro=true";
import { default as tripsvmhiuyYd48Meta } from "/usr/src/app/pages/explore/trips.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as profilempmbOOM2vzMeta } from "/usr/src/app/pages/profile.vue?macro=true";
import { default as editltcz94fjYqMeta } from "/usr/src/app/pages/recipes/[recipeId]/edit.vue?macro=true";
import { default as indexEa8RzHc1YrMeta } from "/usr/src/app/pages/recipes/[recipeId]/index.vue?macro=true";
import { default as _91recipeId_93WlRcqZqCbOMeta } from "/usr/src/app/pages/recipes/[recipeId].vue?macro=true";
import { default as add27b03DQK5FMeta } from "/usr/src/app/pages/recipes/add.vue?macro=true";
import { default as indexxbYAr17cczMeta } from "/usr/src/app/pages/recipes/index.vue?macro=true";
import { default as settingsnXXGFi4oVlMeta } from "/usr/src/app/pages/settings.vue?macro=true";
import { default as index52cOMeYxLuMeta } from "/usr/src/app/pages/trips/[tripId]/[day]/index.vue?macro=true";
import { default as _91day_93PQ28wzT4J7Meta } from "/usr/src/app/pages/trips/[tripId]/[day].vue?macro=true";
import { default as indexHcMpEm5Qw4Meta } from "/usr/src/app/pages/trips/[tripId]/index.vue?macro=true";
import { default as macroszXivKkCC0OMeta } from "/usr/src/app/pages/trips/[tripId]/macros.vue?macro=true";
import { default as shopping_45listcdSEqV4jSaMeta } from "/usr/src/app/pages/trips/[tripId]/shopping-list.vue?macro=true";
import { default as _91tripId_93FEUC5FAhtIMeta } from "/usr/src/app/pages/trips/[tripId].vue?macro=true";
import { default as indexArubO2tFRQMeta } from "/usr/src/app/pages/trips/index.vue?macro=true";
export default [
  {
    name: "acknowledgements",
    path: "/acknowledgements",
    component: () => import("/usr/src/app/pages/acknowledgements.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/usr/src/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "explore-foods",
    path: "/explore/foods",
    component: () => import("/usr/src/app/pages/explore/foods.vue").then(m => m.default || m)
  },
  {
    name: "explore-recipes",
    path: "/explore/recipes",
    component: () => import("/usr/src/app/pages/explore/recipes.vue").then(m => m.default || m)
  },
  {
    name: "explore-trips",
    path: "/explore/trips",
    component: () => import("/usr/src/app/pages/explore/trips.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/usr/src/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: _91recipeId_93WlRcqZqCbOMeta?.name,
    path: "/recipes/:recipeId()",
    component: () => import("/usr/src/app/pages/recipes/[recipeId].vue").then(m => m.default || m),
    children: [
  {
    name: "recipes-recipeId-edit",
    path: "edit",
    component: () => import("/usr/src/app/pages/recipes/[recipeId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "recipes-recipeId",
    path: "",
    component: () => import("/usr/src/app/pages/recipes/[recipeId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "recipes-add",
    path: "/recipes/add",
    component: () => import("/usr/src/app/pages/recipes/add.vue").then(m => m.default || m)
  },
  {
    name: "recipes",
    path: "/recipes",
    component: () => import("/usr/src/app/pages/recipes/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/usr/src/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: _91tripId_93FEUC5FAhtIMeta?.name,
    path: "/trips/:tripId()",
    component: () => import("/usr/src/app/pages/trips/[tripId].vue").then(m => m.default || m),
    children: [
  {
    name: _91day_93PQ28wzT4J7Meta?.name,
    path: ":day()",
    component: () => import("/usr/src/app/pages/trips/[tripId]/[day].vue").then(m => m.default || m),
    children: [
  {
    name: "trips-tripId-day",
    path: "",
    component: () => import("/usr/src/app/pages/trips/[tripId]/[day]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trips-tripId",
    path: "",
    meta: indexHcMpEm5Qw4Meta || {},
    component: () => import("/usr/src/app/pages/trips/[tripId]/index.vue").then(m => m.default || m)
  },
  {
    name: "trips-tripId-macros",
    path: "macros",
    component: () => import("/usr/src/app/pages/trips/[tripId]/macros.vue").then(m => m.default || m)
  },
  {
    name: "trips-tripId-shopping-list",
    path: "shopping-list",
    component: () => import("/usr/src/app/pages/trips/[tripId]/shopping-list.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trips",
    path: "/trips",
    component: () => import("/usr/src/app/pages/trips/index.vue").then(m => m.default || m)
  }
]