<script setup lang="ts">

import {userKey} from '~/composables/injectionKeys'
import type {
    UserDto,
    TripDto,
    FoodDto,
    TripEdibleDto, UserSettingsDto
} from '~/data/drizzle/schema/schemaTypes'
import {isAfter} from 'date-fns'
import {useTripApi} from '~/composables/useTripApi'

const {trackEvent} = usePlausible()
const { setLocale, getBrowserLocale, defaultLocale } = useI18n()

// State
const settings = computed(() => user.value?.settings)
const isLoggedIn = computed(() => !!user.value)
const futureTrips = computed(() => trips?.value.filter(t => isAfter(t.end_date, new Date())) as TripDto[])

const {data: user, clear: clearUser, refresh: refreshUser} = await useFetch('/api/auth/me', {
    onRequestError() {
        user.value = undefined
    },
    onResponse({response}) {
        if (response._data.settings.theme === 'valentine') {
            setLocale('en-GIRL')
        } else {
            setLocale(getBrowserLocale() ?? defaultLocale)
        }
    }
})
const {data: trips, clear: clearTrips, refresh: refreshTrips} = await useFetch<TripDto[]>('/api/trips', {
    watch: [user],
    onRequestError() {
        trips.value = []
    },
    default: () => []
})

// Methods

const login = async (username: string, password: string) => {
    let u = await $fetch('/api/auth/login', {method: 'post', body: {username, password}})
    trackEvent('Login', {props: {user: u?.username}})
    await refreshUser()
    return u
}

const createAccount = async (username: string, password: string) => {
    let u = await $fetch('/api/auth/create', {method: 'post', body: {username, password}}) as unknown as UserDto
    user.value = u
    trackEvent('CreateAccount', {props: {user: u.username}})
    return u
}

const logout = async () => {
    await $fetch('/api/auth/logout', {method: 'post'})
    clearUser()
    trips.value = []
    navigateTo('/')
}

const canEdit = (created_by: number | undefined) => created_by !== undefined && created_by === user.value?.id

const createTrip = async (description: string, start_date: number, length: number, calorie_target: number) => {
    let trip = await useTripApi().createTrip(description, start_date, length, calorie_target)
    trackEvent('AddTrip')
    await refreshTrips()
    return trip
}
const deleteTrip = async (tripId: string) => {
    await useTripApi().deleteTrip(tripId)
    trackEvent('DeleteTrip')
    await refreshTrips()
}
const copyFoodTo = async (tid: string, day: number, tripFood: TripEdibleDto[] = [], food: FoodDto[] = []) => {
    return await useTripApi().copyFoodsToTrip(tid, day, tripFood.map(f => f.id), food.map(f => f.id))
}
const updateUserSettings = async (settings: UserSettingsDto) => {
    await $fetch<UserDto>('/api/settings', {
        method: 'put',
        body: settings
    })
    await refreshUser()
    return user.value?.settings ?? {} as UserSettingsDto
}

refreshUser()

provide(userKey, {
    user,
    settings,
    updateUserSettings,
    refreshUser,
    isLoggedIn,
    canEdit,
    trips,
    futureTrips,
    login,
    logout,
    createAccount,
    createTrip,
    deleteTrip,
    copyFoodTo
})

</script>

<template>
    <Suspense>
        <slot />
    </Suspense>
</template>
