<template>
    <div>
        <UserProvider>
            <NuxtLoadingIndicator />
            <NuxtLayout>
                <NuxtPage />
            </NuxtLayout>
        </UserProvider>
    </div>
</template>

<script lang="ts" setup>

import UserProvider from './components/providers/UserProvider.vue'

useHead({
    title: 'Backpacking Food',
    script: [
        {
            defer: true,
            src: 'https://plausible.bj13.us/js/script.local.js'
        }
    ],
    meta: [
        {name: 'msapplication-TileColor', content: '#00a300'},
        {name: 'theme-color', content: '#ffffff'}
    ],
    link: [
        {rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png'},
        {rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png'},
        {rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png'},
        {rel: 'manifest', href: '/site.webmanifest'},
        {rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5'}

    ]
})

</script>
