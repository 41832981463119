import type {UserDto} from '~/data/drizzle/schema/schemaTypes'

export const usePlausible = () => {
    type PlausibleEvent = "pageview" | "Login" | "CreateAccount" | "AddTrip" | "AddFood" | "UpdateTrip" | "DeleteTrip"

    return {
        trackEvent: (evt: PlausibleEvent, data?: any) => {
            const plausible = (window as any)?.plausible;
            const user = useCookie<UserDto | undefined>('user')
            if (plausible) plausible(evt, {props: {user: user.value?.username ?? 'anon'}, ...data});
        }
    };
}

